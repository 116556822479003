.grid-view-container {
	width: 100%;

	.grid-view-title{
		text-align: center;
		font-weight: bold;
	}
	.grid-view {
    	font-size: 11px;
		thead th {
			padding: 7px;
			color: #fff;
			background-color: #333;
		}
		tbody td {
			padding:7px;
		}
	}

  .pagination .item{
    padding: 5px;
    font-size: 11px;
  }
}
