.black {
  color: $black;
}

.items-wrapper {
  max-height: 350px;
  overflow: auto;
}

.products-container {
  .link-to-parent {
    color: rgba(3, 3, 3, 0.5);
    font-size: 20px;
    width: 25px;
    display: inline-block;
    text-align: center;
  }

  .products-header {
    display: block;
    align-items: center;
  }

  .breadcrumbs {
    font-size: em(14);
    background-color: $gray-100;
    padding: 4px 6px 4px 6px;
    border: solid 1px #e5d7d7;

    .breadcrumb-next {
      font-size: 14px;
      margin: 0px 6px;
    }
  }

  .nav-actions {
    display: block;
    text-align: right;
    color: #8091c5;
    padding: 7px 10px;
    display: inline-block;

    a,
    a:hover {
      color: $primary-2;
    }

    .item-icon {
      cursor: pointer;
    }

    .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .actions {
    color: #8091c5;
    display: inline-block;
    float: right;

    a,
    a:hover {
      color: #8091c5;

      i {
        margin: 0;
      }
    }

    .item-icon {
      cursor: pointer;
    }

    .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .products-browser {
    .enable-vertical-scroll {
      height: 100% !important;
      overflow-y: auto;
      padding: 0px;
    }

    padding: 0 em(10);
    margin-top: 0 !important;
    background-color: $white;
    overflow: hidden;

    .products-browser-row,
    .products-browser-tree,
    .products-browser-list {
      height: 99% !important;
    }

    .segment {
      box-shadow: none;
    }

    .accordion {
      box-shadow: none;
      margin-bottom: 0.75em;

      .title {
        padding-bottom: 0;
        border-top: none;
        color: rgba(0, 0, 0, 0.4);

        &.disabled {
          color: #787878;

          .icon-tree-open::before {
            color: $color-icon-disable;
            background: $background-icon;
          }

          .icon-folder-normal::before {

            color: $color-icon-disable;
            background: $background-icon;
          }

          .icon-tree-close {

            .path1 {
              &::before {
                color: $color-disable-tree-icon;
              }
            }
          }



          a,
          a:hover {
            color: #787878;
          }

          &.selected {
            &.disabled {
              .icon-tree-open::before {
                color: $color-icon-disable;
                background: $background-icon;
              }

              .icon-folder-normal::before {

                color: $color-icon-disable;
                background: $background-icon;
              }

              .icon-tree-close {

                .path1 {
                  &::before {
                    color: $color-disable-tree-icon;
                  }
                }
              }

              a {
                color: #545454;
              }
            }
          }
        }

        &.selected {
          color: #333;


          a,
          a:hover {
            font-weight: bold;
            // text-decoration: underline;
          }
        }

        a,
        a:hover {
          color: #333;
          text-decoration: none;
          font-weight: normal;

          &:hover {
            text-decoration: underline;
          }
        }

        .product:before {
          content: "\f067";
        }

        &.active {

          /*  */
          .product:before {
            content: "\f068";
          }

          &.disabled {
            a {
              color: #787878;
            }

          }
        }

        .product.no-children:before {
          opacity: 0.3;
          content: "\f111";
        }
      }
    }

    .products-segment-div {
      width: 100%;
      padding: 0;
      margin: 0;

      .ui.container {
        padding: 0 !important;
        margin: 0 !important;
      }

      small {
        font-size: 100%;
      }
    }

    .items-list-tree {
      height: 385px;
      border-right: 1px solid #eee;
      overflow-y: auto;

      .item-icon {
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;
        color: #888;
        font-size: 18px;
      }

      .item-title {
        padding: 3px;
        display: inline-block;
        font-size: 12px;
        cursor: pointer;
      }

      .item-selected {
        background-color: #33f;
        color: $white;
      }

      .item-children {
        display: none;

        .item {
          border-left: 1px dotted #333;
        }

        .item:last-child {
          border-left: none;
        }

        cursor: pointer;
        margin-left: 16px;
      }

      .item.expand-children>.item-children {
        display: block;
      }
    }

    .product-search-bar {
      margin: 0 0 em(16) 0;
    }
  }
}

.products-browser-tree {
  background: $gray-100;
  padding: 5px 0 !important;

  .ui {
    &.segment {
      background: none;

      .accordion.ui.styled {
        background: none;
      }
    }

    &.styled.accordion .accordion .content,
    &.styled.accordion .content {
      margin-left: 1.7rem;
      padding: 0.5em 0 0.5em !important;
      position: relative;

      &.active:before {
        border-left: 1.4px $color-tree-connected-line solid;
        content: "";
        height: calc(100% - 16px);
        left: -1px;
        position: absolute;
        top: -4px;
        width: 11px;
      }

      &.single-child:before {
        border-left: none !important;
      }
    }
  }

  .product-tree-wrapper {
    max-height: 645px;
    min-height: 400px;
  }
}

.products-container .products-browser .accordion {
  .title {
    font-size: 12px;
  }
}

.products-container .products-browser .accordion {
  >.title {
    &:before {
      display: none;
    }
  }
}

.products-container .products-browser .accordion .title {

  .icon-tree-close,
  .icon-tree-open,
  .icon-folder-normal {
    float: left;
    font-size: 20px;
    font-weight: bold;

    &:before {
      color: $primary-2;
    }

    .path1 {
      &:before {
        color: $primary-2;
      }
    }
  }

  a {
    font-size: 13px;
    top: 10px;
    width: 83%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }
  }

  &.selected {

    .icon-tree-close,
    .icon-tree-open,
    .icon-folder-normal {
      &:before {
        color: $secondary;
      }

      .path1 {
        &:before {
          color: $secondary;
        }
      }
    }

    a {
      color: $secondary;
      font-weight: bold;
    }

    &.has-files {
      .icon-folder-normal {
        &::after {
          box-shadow: 0px $icon-filled-height $secondary inset;
        }
      }

    }

    &.disabled {
      &.has-files {
        .icon-folder-normal {
          &::after {
            box-shadow: 0px $icon-filled-height $color-icon-disable inset;
          }
        }

      }
    }
  }

  &.active {

    .icon-tree-close,
    .icon-tree-open,
    .icon-folder-normal {
      &:before {
        color: $primary-2;
      }

      .path1 {
        &:before {
          color: $primary-2;
        }
      }
    }

    a {
      font-weight: bold;
    }

    &.disabled {

      a {
        color: #333;
      }

      .icon-tree-open::before {
        color: $color-icon-disable;
        background: $background-icon;
      }

      .icon-folder-normal::before {
        color: $color-icon-disable;
        background: $background-icon;
      }

      .icon-tree-close {

        .path1 {
          &::before {
            color: $color-disable-tree-icon;
          }
        }
      }
    }

    // &.single-child {
  //   &.disabled {

  //     a {
  //       color: #333;
  //     }

  //     .icon-tree-open::before {
  //       color: $color-icon-disable;
  //       background: $background-icon;
  //     }

  //     .icon-folder-normal::before {
  //       color: $color-icon-disable;
  //       background: $background-icon;
  //     }

  //     .icon-tree-close {

  //       .path1 {
  //         &::before {
  //           color: $color-disable-tree-icon;
  //         }
  //       }
  //     }
  //   }
  // }
  }

  &.active.selected {

    .icon-tree-close,
    .icon-tree-open,
    .icon-folder-normal {
      &:before {
        color: $secondary;
      }

      .path1 {
        &:before {
          color: $secondary;
        }
      }
    }

    a {
      font-weight: bold;
    }

    &.has-files {
      .icon-folder-normal {
        &::after {
          box-shadow: 0px $icon-filled-height $secondary inset;
        }
      }

    }
  }

  position: relative;

  &:before {
    border-bottom: 1.4px $color-tree-connected-line solid;
    border-left: 1.4px $color-tree-connected-line solid;
    content: "";
    height: 34px;
    left: -1px;
    position: absolute;
    top: -11px;
    width: 13px;
  }

  &.has-files {
    .icon-folder-normal {
      &::after {
        content: '';
        height: 19px;
        width: 20px;
        position: absolute;
        box-shadow: 0px $icon-filled-height $primary-2 inset;
        left: 14px;
        margin-left: -1px;
      }
    }
  }
}

// Download preview
#proxy-renderer {
  overflow: auto;
  max-height: 50vh;

  #txt-renderer {
    text-align: left;
  }
}

.product-responsive-row {
  margin: -14px 0 0 0;
  display: flex;
  flex-direction: column;
}