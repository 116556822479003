.notifications-page {
}

.notifications-sidebar {
  &.ui.right.sidebar {
    z-index: 2000;
    width: 500px;
    top: 101px;
    height: calc(100vh - 101px) !important;
  }
}

.notification-wrapper {
  margin: 16px 0 0 0;

  .sort-heading {
    // font-size: rem(16);
    color: $secondary;
    letter-spacing: 0.6px;
    padding: 0 0 4px 0;
    display: flex;
    align-items: center;
    font-weight: 700;

    .sort-by-type {
      font-size: em(18);
      color: $black;
    }

    .mark-action {
      text-align: right;
      color: $secondary;
    }
  }

  .notification-item {
    display: flex;
    // background: #efefef;
    //
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 4px;
    margin-top: 8px;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c5c5c5;
      position: absolute;
      left: 0;
      bottom: -4px;
    }

    &.unread {
      background-color: #ffebe8;

      &::after {
        display: none;
      }
    }

    .initials-box {
      display: flex;
      width: 52px;
      height: 52px;
      background: $secondary;
      border-radius: 50%;
      padding: 14px;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      margin: 0 12px 0 0;
    }

    img.initials-box {
      padding: 0;
      background: none;
    }

    .notify-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      font-size: em(16);

      .description {
        letter-spacing: 0.3px;
        word-break: break-word;
        margin: 0;
        font-weight: 600;
        display: flex;
        flex-direction: row;

        .label {
          flex-grow: 1;
        }

        .time {
          color: #84878a;
          padding-left: 8px;
          font-weight: 500;
          white-space: nowrap;
          text-align: center;
          small {
            display: block;
          }
        }
      }

      .more-details {
        font-size: 14px;
        flex-direction: row;
        display: flex;

        .link {
          color: $secondary;
          text-decoration: underline;
          cursor: pointer;
          word-break: break-all;
          flex: 0 0 120px;
        }

        .date-time {
          color: #84878a;
          font-weight: 600;
        }
      }
    }
  }

  .item.search-item-row {
    border: none !important;
    padding: 0 0 16px 0 !important;

    .header {
      display: flex;
      margin: 0;
      padding: 0;
    }

    .content {
      .s-des-wrapper {
      }

      .des-p1 {
        color: #252323;
        margin: 0 0 3px 0;
        width: 50%;
      }

      .des-p2 {
        color: #84878a;
      }
    }
  }
}
