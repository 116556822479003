.hide-container {
  background-color: #eaedef !important;
  box-shadow: none !important;
  border: none !important;
}

// Briefcase
.briefcase-download-popup-list {
  .label {
    display: flex;
    flex-grow: 1;
    font-weight: 600;
    font-size: em(14);
    word-break: break-word;
  }

  button {
    padding: 0;
    a {
      display: flex;
      color: #3b3b3b;
      padding: 6px 6px 6px 6px;
      font-size: 14px;
      word-break: break-all;
      text-align: left;
      font-weight: NORMAL;

      .download.icon {
        font-size: 1.1em;
        margin: 0 4px 3px 0;
      }
    }
  }
}

.download-action {
  display: flex;
  justify-content: space-between;
  margin: em(12) 0 0 0;
  position: relative;

  a {
    text-decoration: none;
    flex-grow: 1;
    display: flex;
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    button {
      width: 100%;
      padding: em(4) 0;

      &:hover {
        text-decoration: none;
        color: $secondary;

        span::before {
          color: $primary-2 !important;
        }
      }
    }
  }
}

.copy-msg {
  margin: 0 0 5px 0;
  position: absolute;
  bottom: -21px;
  font-style: italic;
  font-size: em(11);
  width: 100%;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $secondary;
}
