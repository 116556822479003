//
.m--align-left {
  text-align: left;
}

.m--align-right {
  text-align: right;
}

.m--align-center {
  text-align: center;
}

.m--align-center-im {
  text-align: center !important;
}



.m--top-vertical-align {
  vertical-align: top;
}

.m--middle-vertical-align {
  vertical-align: middle;
}

.m--bottom-vertical-align {
  vertical-align: bottom;
}


// push
//
// push class element

.m--float-left {
  float: left;
}

.m--float-right {
  float: right;
}

.m--float-right-im {
  float: right !important;
}

.m--float-left-im {
  float: left !important;
}


.m--no-border {
  border: 0;
}

// Divider HR line
.divide {
  // background-color: $hl-color-hr-border;
  float: left;
  height: 1px;
  position: relative;
  width: 100%;
}

.m--no-pad-left-right {
  padding-left: 0;
  padding-right: 0;
}

.m--no-pad-left-im {
  padding-left: 0 !important;
}

.m--no-pad-left-right-im {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.m--no-pad-top {
  padding-top: 0;
}

.m--no-pad-top-im {
  padding-top: 0 !important;
}

.pr-1 {
  padding-left: em(16) !important;
}

.pl-1 {
  padding-left: em(16) !important;
}

.padding-1 {
  padding: em(16) !important;
}

.m--display-inline-block {
  display: inline-block;
  float: left;
}



.d-inline {
  display: inline;
}

// Spacing
//
// Utility classes for space margin classes

// No bottom margin
.m--no-margin-bottom {
  margin-bottom: 0;
}

// No top margin
.m--no-margin-top {
  margin-top: 0;
}

// No left margin
.m--no-margin-left {
  margin-left: 0;
}

// No right margin
.m--no-margin-right {
  margin-right: 0;
}

// No margin all
.m--no-margin {
  margin: 0;
}

// No bottom margin
.m--no-margin-bottom {
  margin-bottom: 0;
}

// No top margin
.m--no-margin-top {
  margin-top: 0;
}

// No left margin
.m--no-margin-left-im {
  margin-left: 0 !important;
}

// No right margin
.m--no-margin-right {
  margin-right: 0;
}

// No margin all
.m--no-margin-im {
  margin: 0 !important;
}


// Visibilty
//
// Utility classes for visible element and hide element classes

// No bottom margin
.m--display-none {
  display: none;
}

.m--display-none-im {
  display: none !important;
}

// No top margin
.m--display-inline {
  display: inline;
}


// No left margin
.m--display-inlineblock {
  display: inline-block;
}

.m--position-right-0 {
  right: 0;
}


// Text Color classes



// // Text Size

.fs-1-im {
  font-size: em(16) !important;
}

.fs-2-im {
  font-size: em(20) !important;
}

.width-100 {
  width: 100%;
}

.width-100-im {
  width: 100% !important;
}

.word-wrap {
  overflow-wrap: break-word;
}

.word-break-all {
  word-break: break-all;
}

// margin bottom 1 = 6px

.m-0 {
  margin: 0 !important;
}

.ui.m0-im {
  margin: 0 !important;
}

.mb-1 {
  margin-bottom: 6px;
}

.ml-1-im {
  margin-left: 6px !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-0-im {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 1.5em;
}

.mt-2-im {
  margin-top: em(32) !important;
}

.mb-1 {
  margin-bottom: em(16);
}

.mb-05-im {
  margin-bottom: em(8) !important;
}

.mb-1-im {
  margin-bottom: em(16) !important;
}

.mb-2-im {
  margin-bottom: em(32) !important;
}

.mb-3-im {
  margin-bottom: em(38) !important;
}

.mr-0 {
  margin-right: 0;
}

.mr-0-im {
  margin-right: 0 !important;
}

.mr-half-im {
  margin-right: em(8) !important;
}

.mr-1-im {
  margin-right: em(16) !important;
}

.p-0 {
  padding: 0 !important;
}


.pb-0-im {
  padding-bottom: 0 !important;
}

.pl-0-im {
  padding-left: 0 !important;
}

.pr-0-im {
  padding-right: 0 !important;
}

.pb-1 {
  padding-bottom: em(16);
}

.pb-2 {
  padding-bottom: em(32);
}

// colors
.clr-text-primary {
  color: $white;
}

.clr-text-primary-2 {
  color: $black;
}

.clr-text-secondary {
  color: $flamingo;
}

.clr-primary-link {
  color: $flamingo !important;

  .icon-login-arrow {
    font-size: 9px;
    transform: rotateZ(90deg);
    line-height: 24px;
    float: left;
    margin: 0 3px 0 0;
  }
}

// Stop resize

.textarea-height-fixed-t1 {
  height: 137px;
  resize: none !important;
}