a {
  color: $link-color;

  &:hover {
    color: $link-color;
  }
}

#root {
  overflow: hidden;
  position: relative;
}

.common-wrapper-t2 {}

.side-pane {
  background: $gray-100;
}

.ui {
  .row.app-header-wrapper {
    .column {
      background: $primary;

      .stackable.menu {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        background: transparent;
      }
    }
  }
}

// back button
.btn-back-main {
  float: right;
  font-weight: normal;
  font-size: 1rem;
}

.button-danger:hover {
  color: white;
  border: solid 1px #de3a3a;
  background-color: #de3a3a;
}

.search-bar-small {
  width: 300px;
}

.dropdown-small {
  min-width: 11em !important;
}

.black-font {
  color: #000000;
  font-weight: bold;
}

.light-background-shade {
  border-radius: 5px;
  background-color: rgba($color: #e9edf0, $alpha: 0.35) !important;
}

.pointer-table {
  cursor: pointer !important;

  i {
    margin: 0;
  }
}

.dark-blue-background-shade {
  background-color: $app-base-color !important;
}

.white-background {
  background-color: rgba($color: white, $alpha: 1) !important;
}

.side-marker {
  background-color: $app-base-color;
  height: 31px;
  left: 0;
  position: absolute;
  width: 5px;
}

.margin-boundary {
  margin: 15px;
}

.margin {
  margin: 15px;
}

.empty-height {
  height: 35px;
}

.side-bar-icon {
  // width: 25% !important;
}

.oval {
  width: 7px;
  height: 7px;
  object-fit: contain;
  background-color: #ececec;
}

.fp-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap; //This will keep the layout at all screen sizes.
}

.sidebar {
  width: 30%;
  position: absolute;
  height: calc(100vh - 10px);
}

.fp-panel-main {
  width: 100%;
}

.top {
  vertical-align: top;
}

label.error-message {
  font-size: 11px !important;
  font-style: italic !important;
  font-weight: normal !important;

  &:after {
    content: none !important;
  }
}

.head-sub-t3 {
  font-size: 12px;
  font-weight: bold;
  color: $black;
}

.sub-nav-right-wrapper {
  border-radius: 3px !important;
  max-height: calc(100vh - 126px);
  overflow-x: hidden;
  overflow-y: auto;

  .ui.compact.menu {
    width: 100% !important;

    >.item {
      padding: 6px 0 8px 0;
      float: left;
      width: 100%;

      .header {
        color: $clr-sub-nav;
        float: left;
        font-size: 16px;
        margin: 0;
        padding: 0;
        width: 100%;

        .ico-box,
        img {
          float: left;
          width: 40px;
          height: 40px;
        }

        .ico-box-wrap {
          float: left;
          text-align: center;
        }

        .nav-text {
          padding: 10px 0 0 16px;
          font-size: 15px;
          float: left;
        }
      }

      .menu.secondary-nav-menu-sub-nav {
        float: left;
        margin: 0 0 12px 0;
        padding: 0;
        position: relative;
        width: 100%;

        .item {
          color: $clr-sub-nav;
          font-size: 15px;
          margin-left: 54px;
          padding-bottom: 10px;
          padding-top: 10px;

          &.active {
            background: none !important;
            border-color: transparent !important;
            border-radius: none !important;
            color: none !important;
            margin-bottom: 0 !important;
            margin-left: 54px !important;
            margin-right: 0 !important;
            margin-top: 0 !important;

            &:before {
              background: $secondary !important;
            }
          }

          &:before {
            display: block !important;
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $clr-sub-nav-dot !important;
            position: absolute;
            top: 42%;
            z-index: 3;
          }

          &:after {
            background: $clr-sub-nav-dot !important;
            content: "";
            height: 100%;
            left: 3.4px;
            position: absolute;
            top: 18px;
            width: 1.2px;
          }

          .counts {
            position: absolute;
            background: $secondary;
            font-size: em(12);
            color: $primary;
            padding: 2px 4px 3px;
            line-height: 1;
            border-radius: 4px;
            top: 9px;
            margin: 0 0 0 4px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  &.mobile-view {
    padding: 12px 0 0 12px;
    background: #f5f5f5 !important;
    border: 1px solid #d6d6d6;
    border-right: none;
  }
}

//used in product
.custom-link-action {
  a {
    border-radius: $border-radius-form-field;
    width: 40px;
    height: 40px;
    display: inline-block;
    background: #edf2ff;
    color: #8091c5;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    margin: 0 3px;
  }
}

.ico-box-wrap {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: inline-block;

  &.ico-side-nav {
    background: #f5e9e6;
    color: #34363c;

    &.active {
      background: $secondary;

      span {
        &:before {
          color: #fff;
        }
      }
    }

    span {
      &:before {
        color: #7a7c84;
        line-height: 40px;
      }
    }
  }

  &.ico-action {
    background: #edf2ff;
    color: #8091c5;

    &.active {
      background: $primary;

      span {
        &:before {
          color: #fff;
        }
      }
    }

    span {
      &:before {
        color: #8091c5;
        line-height: 40px;
      }
    }
  }
}

//profile
.profile-info-box {}

.profile-info-box-address {}

.common-file-attachment {
  .ui.segment {
    padding: 0;
    margin: 8px 0;

    width: 100%;
    float: left;
    box-shadow: none;

    .ui.list {
      width: 100%;
      float: left;

      .item {
        float: left;
        margin-right: 6px;
        padding-top: 0;

        .ui.large.label {
          font-size: 12px;
        }
      }
    }
  }

  .btn-choose-file {
    margin: 0;
    width: 100%;
  }
}

//page header
.control-action-wrapper {
  float: right;

  &.has-modify {
    display: flex;
    align-items: center;
  }
}

.common-filter-wrapper {
  .button-inline-display {
    .field {
      float: left;
      clear: none;
    }
  }

  .button-primary {
    margin-left: 0;
  }
}

.btn-mobile-sub-nav-open {
  right: -1%;
  position: absolute;
  z-index: 10;
  background: #ececec;
  padding: 10px;
  border: solid 1px #c3c3c3;
  box-shadow: -3px 1px 7px -3px #cecccc;
  border-right: 0;
  border-radius: 6px 0 0 6px;

  top: -16px;

  &:before {
    color: #434343 !important;
  }
}

.btn-mobile-sub-nav-close {
  position: absolute;
  z-index: 86;
  border-radius: 7px;
  padding: 4px 7px 8px 7px;
  right: 8px;
  top: 8px;
  border: solid 1px #e0e0e0;
  background: #ffffff;

  &:before {
    color: #434343 !important;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
  }
}

.btn-header-s1 {
  right: 7px;
  position: absolute;
  top: 6px;
}

// Card view for responsive

.card.card-view-row {
  border-radius: 6px !important;
  width: 100%;

  .content {
    background: #f7f9fa;
    border-radius: 6px !important;
    box-shadow: -1px 3px 4px 0 #d4d4d5, 0 2px 3px 1px #cecece;
    padding: 20px 8px 18px 10px;

    .ui.grid>.column:not(.row) {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }

  .data-view-group-box {
    margin: 0 0 4px 0;
    // display: flex;
    // align-items: stretch;

    label {
      display: block;
      color: #676666;
      font-size: 0.9em;
      letter-spacing: 0.05em;
      font-weight: 500;
      flex-basis: 124px;
    }

    p {
      display: block;
      color: rgba(0, 0, 0, 0.87);
      vertical-align: inherit;
      word-wrap: break-word;
      font-weight: 700;
      font-size: 0.96em;
      width: 100%;
    }
  }
}

.pagination-wrapper {
  text-align: center;
}

.global-search-field-box {
  .field {
    .ui.selection.dropdown {
      margin-right: 2rem;
    }
  }
}

.card-action-box {
  position: absolute !important;
  right: em(-4);
  top: em(6);
  z-index: 10;
}

/* Search result items -- Start -- */

.search-result-wrapper {
  .item.search-item-row {
    border: none !important;
    padding: 0 0 16px 0 !important;

    .header {
      display: flex;
      margin: 0;
      padding: 0;
    }

    // background: red;
    &::first-child {
      padding-top: 0 !important;
    }

    .content {
      a.search-heading {
        font-size: 18px;
        color: $secondary !important;
        letter-spacing: 0.6px;
        padding: 0 0 4px 0;
        display: flex;
        align-items: center;

        p {
          margin: 0 4px 0 0;
        }

        span {
          font-size: 17px;
        }
      }

      b {
        font-size: 11px;
        color: #acadaf;
        margin: 7px 0 0 6px;
        letter-spacing: 0.5px;
      }

      .s-des-wrapper {
        font-size: 14px;
        letter-spacing: 0.3px;
        word-break: break-word;
        margin: 0 0 10px 0;
      }

      .des-p1 {
        color: #252323;
        margin: 0 0 3px 0;
        width: 50%;
      }

      .des-p2 {
        color: #84878a;
      }
    }
  }
}

// Application forms styles

.field.inline-items {
  label {
    input[type="number"] {
      padding: 2px 6px 2px 6px;
      margin: 0 5px 0 4px;
    }
  }
}

.form-info-t2 {
  span {
    color: $primary-2;
  }

  b {
    color: $primary-2;
    word-break: break-word;
  }
}

.feild-add-tags {
  word-break: break-all;
}

// Downloads Search

.field-search-wrapper {
  .seach-item {
    width: 100%;
    margin-bottom: 12px;
    float: left;

    input[type="checkbox"] {
      float: left;
    }

    span {
      float: left;
      line-height: 14px;
      margin: 0 0 0 6px;
    }
  }
}

//* File view control *//

.document-viewer-wrapper {
  #header-bar {
    padding: 0;
    min-height: auto;

    #file-name {
      margin: 0 0 6px 0;
    }
  }
}

#txt-renderer {
  white-space: break-spaces;
  word-break: break-word;
}

//* App Button *//

// Button sizes
.btn-s-t3-im {
  font-size: em(13) !important;
  padding: em(3) em(12) !important;
}

//

::-webkit-calendar-picker-indicator {
  display: none;
}

.ico-img-wrapper {
  width: 100%;
  border: solid 1px #eaedef;
  min-height: 39px;
  margin-bottom: 6px;

  img {
    width: 100%;
  }
}

// Dashboard NDA Popup form
.initiate-form-popup {
  .base-segment-wrapper {
    box-shadow: none;

    .header {
      display: none;
    }

    .ui.grid>.row>[class*="twelve wide large screen"].column {
      width: 100% !important;
    }
  }
}

.invite-user-wrapper {
  background: rgba(233, 237, 240, 0.35) !important;
  box-shadow: none !important;

  .btn-user-delete {
    position: absolute;
    z-index: 2;
    right: 22px;
    background: transparent;
    padding: 0;
    border-radius: 0;
    font-weight: bold;
    top: 50px;

    &::before {
      font-weight: bold;
    }

    &:hover {
      background: none;
    }
  }
}

.project-item {
  .Project {
    font-size: 37px;
    align-items: center;
    display: flex;
    background: #f5e9e6;
    height: 60px;
    padding: 0 7px;
    /* border: solid 1px #d2d0d0; */
    align-items: center;
    margin: 0 10px 0 0;
    border-radius: 10px;

    &::before {
      color: #8a8686;
    }
  }
}

/* Search select */

.select-search {
  .react-select__control {
    border: solid 1px $primary-2;
    border-radius: 0;
    box-shadow: none;

    &:focus {
      border-color: #85b7d9;
    }

    &:hover {
      border: solid 1px $primary-2;
    }

    .react-select__dropdown-indicator {
      svg {
        display: none;
      }

      &:after {
        content: '\f0d7';
      }

      font-family: Dropdown;
      font-size: 12px;
    }

    .react-select__indicator {
      svg {
        height: 18px;
      }
    }
  }
}

.profile-picture {
  height: 35px;
  width: 35px;
  border-radius: 25px;
}

/* Dropdown with tags*/
.dropdown-with-tags {
  .ui.label {
    font-size: em(13) !important;
  }
}