[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'web-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.8rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.base-action-size {
  font-size: 1.36rem;
}

.base-ico-style-1 {
  @extend .base-action-size;
  cursor: pointer;
  font-weight: 500;
  color: #4c4b46;
  margin-left: 5px;
  margin-right: 5px;

  &:hover {
    color: $app-base-color;
  }
}

.icon-logo-2 .path1:before {
  content: "\e998";
  color: rgb(16, 49, 112);
}

.icon-logo-2 .path2:before {
  content: "\e999";
  margin-left: -3.42578125em;
  color: rgb(57, 111, 225);
}

.icon-logo-2 .path3:before {
  content: "\e99a";
  margin-left: -3.42578125em;
  color: rgb(16, 49, 112);
}

.icon-logo-2 .path4:before {
  content: "\e99b";
  margin-left: -3.42578125em;
  color: rgb(57, 111, 225);
}

.icon-logo-2 .path5:before {
  content: "\e99c";
  margin-left: -3.42578125em;
  color: rgb(57, 111, 225);
}

.icon-group-5:before {
  content: "\e900";
  color: $secondary;
}

.icon-g1 .path1:before {
  content: "\e99d";
  color: rgb(239, 246, 255);
}

.icon-g1 .path2:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(16, 49, 112);
}

.icon-g1 .path3:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(16, 49, 112);
}

.icon-g1-state1 .path1:before {
  content: "\e9a0";
  color: $primary;
}

.icon-g1-state1 .path2:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-g1-state1 .path3:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-g4-female .path1:before {
  content: "\e9a3";
  color: rgb(239, 246, 255);
}

.icon-g4-female .path2:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(16, 49, 112);
}

.icon-g4-female-state3 .path1:before {
  content: "\e9a5";
  color: rgb(16, 49, 112);
}

.icon-g4-female-state3 .path2:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-g4-female-state2 .path1:before {
  content: "\e9a7";
  color: rgb(57, 111, 225);
}

.icon-g4-female-state2 .path2:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-g3-state1 .path1:before {
  content: "\e901";
  color: rgb(57, 111, 225);
}

.icon-g3-state1 .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-g3 .path1:before {
  content: "\e903";
  color: rgb(239, 246, 255);
}

.icon-g3 .path2:before {
  content: "\e904";
  margin-left: -1.017578125em;
  color: rgb(16, 49, 112);
}

.icon-g3 .path3:before {
  content: "\e905";
  margin-left: -1.017578125em;
  color: rgb(16, 49, 112);
}

.icon-g3-state2 .path1:before {
  content: "\e906";
  color: rgb(16, 49, 112);
}

.icon-g3-state2 .path2:before {
  content: "\e907";
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}

.icon-g3-state2 .path3:before {
  content: "\e908";
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}

.icon-g2-state1 .path1:before {
  content: "\e909";
  color: rgb(57, 111, 225);
}

.icon-g2-state1 .path2:before {
  content: "\e90a";
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}

.icon-g1-state2 .path1:before {
  content: "\e90b";
  color: rgb(16, 49, 112);
}

.icon-g1-state2 .path2:before {
  content: "\e90c";
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}

.icon-g2 .path1:before {
  content: "\e90d";
  color: rgb(239, 246, 255);
}

.icon-g2 .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(16, 49, 112);
}

.icon-svg .path1:before {
  content: "\e9aa";
  color: rgb(226, 229, 231);
}

.icon-svg .path2:before {
  content: "\e9ab";
  margin-left: -0.869140625em;
  color: rgb(176, 183, 189);
}

.icon-svg .path3:before {
  content: "\e9ac";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-svg .path4:before {
  content: "\e9ad";
  margin-left: -0.869140625em;
  color: rgb(247, 184, 78);
}

.icon-svg .path5:before {
  content: "\e9ae";
  margin-left: -0.869140625em;
  color: rgb(255, 255, 255);
}

.icon-svg .path6:before {
  content: "\e9af";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-png .path1:before {
  content: "\e9b0";
  color: rgb(226, 229, 231);
}

.icon-png .path2:before {
  content: "\e9b1";
  margin-left: -0.869140625em;
  color: rgb(176, 183, 189);
}

.icon-png .path3:before {
  content: "\e9b2";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-png .path4:before {
  content: "\e9b3";
  margin-left: -0.869140625em;
  color: rgb(160, 102, 170);
}

.icon-png .path5:before {
  content: "\e9b4";
  margin-left: -0.869140625em;
  color: rgb(255, 255, 255);
}

.icon-png .path6:before {
  content: "\e9b5";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-zip-compressed-file-format-interface-symbol:before {
  content: "\e90f";
}

.icon-solaris .path1:before {
  content: "\e910";
  color: rgb(238, 37, 41);
}

.icon-solaris .path2:before {
  content: "\e911";
  margin-left: -2.044921875em;
  color: rgb(14, 93, 171);
}

.icon-microsoft .path1:before {
  content: "\e912";
  color: rgb(17, 148, 246);
}

.icon-microsoft .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(71, 176, 75);
}

.icon-microsoft .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 194, 0);
}

.icon-microsoft .path4:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(247, 65, 45);
}

.icon-linux .path1:before {
  content: "\e916";
  color: rgb(255, 255, 255);
}

.icon-linux .path2:before {
  content: "\e917";
  margin-left: -0.8623046875em;
  color: rgb(230, 150, 41);
}

.icon-linux .path3:before {
  content: "\e918";
  margin-left: -0.8623046875em;
  color: rgb(0, 0, 0);
}

.icon-free-bsd:before {
  content: "\e919";
  color: #d30000;
}

.icon-powerpoint .path1:before {
  content: "\e91a";
  color: rgb(236, 239, 241);
}

.icon-powerpoint .path2:before {
  content: "\e91b";
  margin-left: -1.0693359375em;
  color: rgb(245, 124, 0);
}

.icon-powerpoint .path3:before {
  content: "\e91c";
  margin-left: -1.0693359375em;
  color: rgb(245, 124, 0);
}

.icon-powerpoint .path4:before {
  content: "\e91d";
  margin-left: -1.0693359375em;
  color: rgb(239, 108, 0);
}

.icon-powerpoint .path5:before {
  content: "\e91e";
  margin-left: -1.0693359375em;
  color: rgb(250, 250, 250);
}

.icon-excel .path1:before {
  content: "\e91f";
  color: rgb(236, 239, 241);
}

.icon-excel .path2:before {
  content: "\e920";
  margin-left: -1.0693359375em;
  color: rgb(56, 142, 60);
}

.icon-excel .path3:before {
  content: "\e921";
  margin-left: -1.0693359375em;
  color: rgb(46, 125, 50);
}

.icon-excel .path4:before {
  content: "\e922";
  margin-left: -1.0693359375em;
  color: rgb(250, 250, 250);
}

.icon-word .path1:before {
  content: "\e923";
  color: rgb(236, 239, 241);
}

.icon-word .path2:before {
  content: "\e924";
  margin-left: -1.0693359375em;
  color: rgb(25, 118, 210);
}

.icon-word .path3:before {
  content: "\e925";
  margin-left: -1.0693359375em;
  color: rgb(21, 101, 192);
}

.icon-word .path4:before {
  content: "\e926";
  margin-left: -1.0693359375em;
  color: rgb(250, 250, 250);
}

.icon-pdf .path1:before {
  content: "\e927";
  color: rgb(226, 87, 76);
}

.icon-pdf .path2:before {
  content: "\e928";
  margin-left: -0.876953125em;
  color: rgb(181, 54, 41);
}

.icon-pdf .path3:before {
  content: "\e929";
  margin-left: -0.876953125em;
  color: rgb(255, 255, 255);
}

.icon-audio .path1:before {
  content: "\e92a";
  color: rgb(247, 65, 45);
}

.icon-audio .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-file-copy .path1:before {
  content: "\e92c";
  color: rgb(0, 172, 234);
}

.icon-file-copy .path2:before {
  content: "\e92d";
  margin-left: -0.783203125em;
  color: rgb(0, 239, 209);
}

.icon-file-copy .path3:before {
  content: "\e92e";
  margin-left: -0.783203125em;
  color: rgb(8, 56, 99);
}

.icon-cpio-copy-2 .path1:before {
  content: "\e92f";
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path2:before {
  content: "\e930";
  margin-left: -1.1552734375em;
  color: rgb(140, 138, 139);
}

.icon-cpio-copy-2 .path3:before {
  content: "\e931";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path4:before {
  content: "\e932";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path5:before {
  content: "\e933";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path6:before {
  content: "\e934";
  margin-left: -1.1552734375em;
  color: rgb(156, 154, 154);
}

.icon-cpio-copy-2 .path7:before {
  content: "\e935";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path8:before {
  content: "\e936";
  margin-left: -1.1552734375em;
  color: rgb(156, 154, 154);
}

.icon-cpio-copy-2 .path9:before {
  content: "\e937";
  margin-left: -1.1552734375em;
  color: rgb(245, 212, 121);
}

.icon-cpio-copy-2 .path10:before {
  content: "\e938";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path11:before {
  content: "\e939";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path12:before {
  content: "\e93a";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path13:before {
  content: "\e93b";
  margin-left: -1.1552734375em;
  color: rgb(189, 187, 188);
}

.icon-cpio-copy-2 .path14:before {
  content: "\e93c";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path15:before {
  content: "\e93d";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path16:before {
  content: "\e93e";
  margin-left: -1.1552734375em;
  color: rgb(35, 31, 32);
}

.icon-cpio-copy-2 .path17:before {
  content: "\e93f";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path18:before {
  content: "\e940";
  margin-left: -1.1552734375em;
  color: rgb(255, 255, 255);
  opacity: 0.45;
}

.icon-cpio-copy-2 .path19:before {
  content: "\e941";
  margin-left: -1.1552734375em;
  color: rgb(245, 212, 121);
}

.icon-cpio-copy-2 .path20:before {
  content: "\e942";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path21:before {
  content: "\e943";
  margin-left: -1.1552734375em;
  color: rgb(123, 121, 121);
}

.icon-cpio-copy-2 .path22:before {
  content: "\e944";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path23:before {
  content: "\e945";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path24:before {
  content: "\e946";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-copy-2 .path25:before {
  content: "\e947";
  margin-left: -1.1552734375em;
  color: rgb(140, 138, 139);
}

.icon-cpio-copy-2 .path26:before {
  content: "\e948";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-rdf-logo .path1:before {
  content: "\e949";
  color: rgb(0, 0, 0);
}

.icon-rdf-logo .path2:before {
  content: "\e94a";
  margin-left: -0.9140625em;
  color: rgb(255, 255, 255);
}

.icon-rdf-logo .path3:before {
  content: "\e94b";
  margin-left: -0.9140625em;
  color: rgb(12, 71, 156);
}

.icon-rdf-logo .path4:before {
  content: "\e94c";
  margin-left: -0.9140625em;
  color: rgb(255, 255, 255);
}

.icon-rdf-logo .path5:before {
  content: "\e94d";
  margin-left: -0.9140625em;
  color: rgb(255, 255, 255);
}

.icon-rdf-logo .path6:before {
  content: "\e94e";
  margin-left: -0.9140625em;
  color: rgb(255, 255, 255);
}

.icon-rdf-logo .path7:before {
  content: "\e94f";
  margin-left: -0.9140625em;
  color: rgb(255, 255, 255);
}

.icon-rpm-file-format-symbol:before {
  content: "\e950";
  color: $secondary;
}

.icon-occad-libarary .path1:before {
  content: "\e951";
  color: rgb(231, 22, 51);
}

.icon-occad-libarary .path2:before {
  content: "\e952";
  margin-left: -1.1484375em;
  color: rgb(51, 51, 50);
}

.icon-java .path1:before {
  content: "\e953";
  color: rgb(219, 56, 14);
}

.icon-java .path2:before {
  content: "\e954";
  margin-left: -0.7412109375em;
  color: rgb(219, 56, 14);
}

.icon-java .path3:before {
  content: "\e955";
  margin-left: -0.7412109375em;
  color: rgb(115, 161, 251);
}

.icon-java .path4:before {
  content: "\e956";
  margin-left: -0.7412109375em;
  color: rgb(115, 161, 251);
}

.icon-java .path5:before {
  content: "\e957";
  margin-left: -0.7412109375em;
  color: rgb(115, 161, 251);
}

.icon-html-5:before {
  content: "\e958";
  color: #dd512a;
}

.icon-cpio-1 .path1:before {
  content: "\e959";
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path2:before {
  content: "\e95a";
  margin-left: -1.1552734375em;
  color: rgb(140, 138, 139);
}

.icon-cpio-1 .path3:before {
  content: "\e95b";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path4:before {
  content: "\e95c";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path5:before {
  content: "\e95d";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path6:before {
  content: "\e95e";
  margin-left: -1.1552734375em;
  color: rgb(156, 154, 154);
}

.icon-cpio-1 .path7:before {
  content: "\e95f";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path8:before {
  content: "\e960";
  margin-left: -1.1552734375em;
  color: rgb(156, 154, 154);
}

.icon-cpio-1 .path9:before {
  content: "\e961";
  margin-left: -1.1552734375em;
  color: rgb(245, 212, 121);
}

.icon-cpio-1 .path10:before {
  content: "\e962";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path11:before {
  content: "\e963";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path12:before {
  content: "\e964";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path13:before {
  content: "\e965";
  margin-left: -1.1552734375em;
  color: rgb(189, 187, 188);
}

.icon-cpio-1 .path14:before {
  content: "\e966";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path15:before {
  content: "\e967";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path16:before {
  content: "\e968";
  margin-left: -1.1552734375em;
  color: rgb(35, 31, 32);
}

.icon-cpio-1 .path17:before {
  content: "\e969";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path18:before {
  content: "\e96a";
  margin-left: -1.1552734375em;
  color: rgb(255, 255, 255);
  opacity: 0.45;
}

.icon-cpio-1 .path19:before {
  content: "\e96b";
  margin-left: -1.1552734375em;
  color: rgb(245, 212, 121);
}

.icon-cpio-1 .path20:before {
  content: "\e96c";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path21:before {
  content: "\e96d";
  margin-left: -1.1552734375em;
  color: rgb(123, 121, 121);
}

.icon-cpio-1 .path22:before {
  content: "\e96e";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path23:before {
  content: "\e96f";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path24:before {
  content: "\e970";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio-1 .path25:before {
  content: "\e971";
  margin-left: -1.1552734375em;
  color: rgb(140, 138, 139);
}

.icon-cpio-1 .path26:before {
  content: "\e972";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path1:before {
  content: "\e973";
  color: rgb(0, 0, 0);
}

.icon-cpio .path2:before {
  content: "\e974";
  margin-left: -1.1552734375em;
  color: rgb(140, 138, 139);
}

.icon-cpio .path3:before {
  content: "\e975";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path4:before {
  content: "\e976";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path5:before {
  content: "\e977";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path6:before {
  content: "\e978";
  margin-left: -1.1552734375em;
  color: rgb(156, 154, 154);
}

.icon-cpio .path7:before {
  content: "\e979";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path8:before {
  content: "\e97a";
  margin-left: -1.1552734375em;
  color: rgb(156, 154, 154);
}

.icon-cpio .path9:before {
  content: "\e97b";
  margin-left: -1.1552734375em;
  color: rgb(245, 212, 121);
}

.icon-cpio .path10:before {
  content: "\e97c";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path11:before {
  content: "\e97d";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path12:before {
  content: "\e97e";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path13:before {
  content: "\e97f";
  margin-left: -1.1552734375em;
  color: rgb(189, 187, 188);
}

.icon-cpio .path14:before {
  content: "\e980";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path15:before {
  content: "\e981";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path16:before {
  content: "\e982";
  margin-left: -1.1552734375em;
  color: rgb(35, 31, 32);
}

.icon-cpio .path17:before {
  content: "\e983";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path18:before {
  content: "\e984";
  margin-left: -1.1552734375em;
  color: rgb(255, 255, 255);
  opacity: 0.45;
}

.icon-cpio .path19:before {
  content: "\e985";
  margin-left: -1.1552734375em;
  color: rgb(245, 212, 121);
}

.icon-cpio .path20:before {
  content: "\e986";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path21:before {
  content: "\e987";
  margin-left: -1.1552734375em;
  color: rgb(123, 121, 121);
}

.icon-cpio .path22:before {
  content: "\e988";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path23:before {
  content: "\e989";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path24:before {
  content: "\e98a";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-cpio .path25:before {
  content: "\e98b";
  margin-left: -1.1552734375em;
  color: rgb(140, 138, 139);
}

.icon-cpio .path26:before {
  content: "\e98c";
  margin-left: -1.1552734375em;
  color: rgb(0, 0, 0);
}

.icon-vx-works:before {
  content: "\e98d";
  color: #d3231f;
}

.icon-menu-dots .path1:before {
  content: "\e98e";
  color: rgb(57, 111, 225);
}

.icon-menu-dots .path2:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-menu-dots .path3:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-menu-dots .path4:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-video .path1:before {
  content: "\e992";
  color: #ffc200;
}

.icon-video .path2:before {
  content: "\ea26";
  color: #fff;
  margin-left: -1em;
}

.icon-microsoft-executeable .path1:before {
  content: "\e994";
  color: rgb(17, 148, 246);
}

.icon-microsoft-executeable .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(71, 176, 75);
}

.icon-microsoft-executeable .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 194, 0);
}

.icon-microsoft-executeable .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(247, 65, 45);
}

.icon-box-view:before {
  content: "\e9b6";
  color: #969da8;
}

.icon-list-view:before {
  content: "\e9b7";
  color: $secondary;
}

.icon-login-arrow:before {
  content: "\e9b8";
  color: $secondary;
}

.icon-menu:before {
  content: "\e9b9";
  color: $secondary;
}

.icon-edit:before {
  @extend .base-ico-style-1;
  font-size: 1.26rem;
  content: "\e9ba";
}

.icon-delete:before {
  @extend .base-ico-style-1;
  content: "\e9bb";
}

.icon-download:before {
  @extend .base-ico-style-1;
  content: "\e9bc";
}

.icon-folder-normal:before {
  content: "\e9bd";
  color: $secondary;
}

.icon-tree-close-details:before {
  content: "\e9be";
  color: $secondary;
}

.icon-tree-open:before {
  content: "\e9bf";
  color: $secondary;
}

.icon-tree-close .path1:before {
  content: "\e9c0";
  color: rgb(57, 111, 225);
}

.icon-tree-close .path2:before {
  content: "\e9c1";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}

// this search icon is replace by view icons as per client requirment
.icon-search:before {
  @extend .base-ico-style-1;
  // content: "\e9c2";
  content: "\ea0d";
}

.icon-search-new:before {
  @extend .base-ico-style-1;
  content: "\e9c2";
}

.icon-jpg .path1:before {
  content: "\e9c3";
  color: rgb(226, 229, 231);
}

.icon-jpg .path2:before {
  content: "\e9c4";
  margin-left: -0.869140625em;
  color: rgb(176, 183, 189);
}

.icon-jpg .path3:before {
  content: "\e9c5";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-jpg .path4:before {
  content: "\e9c6";
  margin-left: -0.869140625em;
  color: rgb(80, 190, 232);
}

.icon-jpg .path5:before {
  content: "\e9c7";
  margin-left: -0.869140625em;
  color: rgb(255, 255, 255);
}

.icon-jpg .path6:before {
  content: "\e9c8";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-multiple-files .path1:before {
  content: "\e9c9";
  color: rgb(0, 94, 206);
}

.icon-multiple-files .path2:before {
  content: "\e9ca";
  margin-left: -0.9833984375em;
  color: rgb(204, 164, 0);
}

.icon-multiple-files .path3:before {
  content: "\e9cb";
  margin-left: -0.9833984375em;
  color: rgb(255, 205, 0);
}

.icon-multiple-files .path4:before {
  content: "\e9cc";
  margin-left: -0.9833984375em;
  color: rgb(235, 191, 0);
}

.icon-multiple-files .path5:before {
  content: "\e9cd";
  margin-left: -0.9833984375em;
  color: rgb(87, 164, 255);
}

.icon-multiple-files .path6:before {
  content: "\e9ce";
  margin-left: -0.9833984375em;
  color: rgb(36, 136, 255);
}

.icon-multiple-files .path7:before {
  content: "\e9cf";
  margin-left: -0.9833984375em;
  color: rgb(241, 242, 242);
}

.icon-winrar .path1:before {
  content: "\e9d0";
  color: rgb(0, 0, 0);
}

.icon-winrar .path2:before {
  content: "\e9d1";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path3:before {
  content: "\e9d2";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path4:before {
  content: "\e9d3";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path5:before {
  content: "\e9d4";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path6:before {
  content: "\e9d5";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path7:before {
  content: "\e9d6";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path8:before {
  content: "\e9d7";
  margin-left: -1.2109375em;
  color: rgb(181, 191, 44);
}

.icon-winrar .path9:before {
  content: "\e9d8";
  margin-left: -1.2109375em;
  color: rgb(13, 13, 13);
}

.icon-winrar .path10:before {
  content: "\e9d9";
  margin-left: -1.2109375em;
  color: rgb(242, 167, 215);
}

.icon-winrar .path11:before {
  content: "\e9da";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path12:before {
  content: "\e9db";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path13:before {
  content: "\e9dc";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path14:before {
  content: "\e9dd";
  margin-left: -1.2109375em;
  color: rgb(13, 13, 13);
}

.icon-winrar .path15:before {
  content: "\e9de";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path16:before {
  content: "\e9df";
  margin-left: -1.2109375em;
  color: rgb(25, 25, 25);
}

.icon-winrar .path17:before {
  content: "\e9e0";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path18:before {
  content: "\e9e1";
  margin-left: -1.2109375em;
  color: rgb(13, 13, 13);
}

.icon-winrar .path19:before {
  content: "\e9e2";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winrar .path20:before {
  content: "\e9e3";
  margin-left: -1.2109375em;
  color: rgb(25, 25, 25);
}

.icon-winrar .path21:before {
  content: "\e9e4";
  margin-left: -1.2109375em;
  color: rgb(0, 0, 0);
}

.icon-winzip .path1:before {
  content: "\e9e5";
  color: rgb(255, 255, 255);
}

.icon-winzip .path2:before {
  content: "\e9e6";
  margin-left: -0.9853515625em;
  color: rgb(255, 255, 255);
}

.icon-winzip .path3:before {
  content: "\e9e7";
  margin-left: -0.9853515625em;
  color: rgb(93, 101, 103);
}

.icon-winzip .path4:before {
  content: "\e9e8";
  margin-left: -0.9853515625em;
  color: rgb(190, 198, 210);
}

.icon-winzip .path5:before {
  content: "\e9e9";
  margin-left: -0.9853515625em;
  color: rgb(179, 184, 191);
}

.icon-winzip .path6:before {
  content: "\e9ea";
  margin-left: -0.9853515625em;
  color: rgb(190, 198, 210);
}

.icon-winzip .path7:before {
  content: "\e9eb";
  margin-left: -0.9853515625em;
  color: rgb(93, 101, 103);
}

.icon-winzip .path8:before {
  content: "\e9ec";
  margin-left: -0.9853515625em;
  color: rgb(156, 163, 170);
}

.icon-winzip .path9:before {
  content: "\e9ed";
  margin-left: -0.9853515625em;
  color: rgb(156, 163, 170);
}

.icon-winzip .path10:before {
  content: "\e9ee";
  margin-left: -0.9853515625em;
  color: rgb(249, 223, 0);
}

.icon-winzip .path11:before {
  content: "\e9ef";
  margin-left: -0.9853515625em;
  color: rgb(251, 169, 25);
}

.icon-winzip .path12:before {
  content: "\e9f0";
  margin-left: -0.9853515625em;
  color: rgb(249, 223, 0);
}

.icon-others-files .path1:before {
  content: "\e9f1";
  color: rgb(225, 57, 105);
}

.icon-others-files .path2:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-ico-menu-documents:before {
  content: "\e9a9";
  color: #8091c5;
}

.icon-ico-image:before {
  content: "\e9f3";
  color: #8091c5;
}

.icon-ico-sent-files:before {
  content: "\e9f4";
  color: #8091c5;
}

.icon-ico-received-files:before {
  content: "\e9f5";
  color: #8091c5;
}

.icon-ico-add-new:before {
  content: "\e9f6";
  color: #8091c5;
}

.icon-ico-documents:before {
  content: "\e9f7";
  color: #fff;
}

.icon-ico-briefcase:before {
  content: "\e9f8";
  color: #fff;
}

.icon-ico-wall:before {
  content: "\e9f9";
  color: #fff;
}

.icon-my-friends:before {
  content: "\e9fa";
  color: #fff;
}

.icon-profile:before {
  content: "\e9fb";
  color: #fff;
}

.icon-software-license:before {
  content: "\e9fc";
  color: #fff;
}

.icon-contract-1:before {
  content: "\e9fd";
  color: #fff;
}

.icon-software-evaluation:before {
  content: "\e9fe";
  color: #fff;
}

.icon-Agreements:before {
  content: "\e9ff";
  color: #fff;
}

.icon-software-license1:before {
  content: "\ea00";
  color: #fff;
}

.icon-licenses:before {
  content: "\ea01";
  color: #fff;
}

.icon-ico-menu-close:before {
  content: "\ea02";
  color: $secondary;
}

.icon-ico-product:before {
  content: "\ea03";
  color: #fff;
}

.icon-ico-access-control:before {
  content: "\ea04";
  color: #fff;
}

.icon-ico-settings:before {
  content: "\ea05";
  color: #fff;
}

.icon-ico-item-setup:before {
  content: "\ea06";
  color: #fff;
}

.icon-ico-user-setup:before {
  content: "\ea07";
  color: #fff;
}

.icon-ico-projects:before {
  content: "\ea08";
  color: #fff;
}

.icon-ico-project:before {
  content: "\ea09";
  color: #fff;
}

.icon-angle-left-solid:before {
  content: "\ea0a";
  @extend .side-nav-icon;
}

.icon-angle-right-solid:before {
  content: "\ea0b";
  @extend .side-nav-icon;
}

.icon-play:before {
  @extend .base-ico-style-1;
  content: "\ea0c";
  font-weight: 600;
}

.icon-view:before {
  content: "\ea0d";
  @extend .base-ico-style-1;
}

.icon-file-jupyter .path1:before {
  content: "\ea0e";
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path2:before {
  content: "\ea0f";
  margin-left: -0.8623046875em;
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path3:before {
  content: "\ea10";
  margin-left: -0.8623046875em;
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path4:before {
  content: "\ea11";
  margin-left: -0.8623046875em;
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path5:before {
  content: "\ea12";
  margin-left: -0.8623046875em;
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path6:before {
  content: "\ea13";
  margin-left: -0.8623046875em;
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path7:before {
  content: "\ea14";
  margin-left: -0.8623046875em;
  color: rgb(78, 78, 78);
}

.icon-file-jupyter .path8:before {
  content: "\ea15";
  margin-left: -0.8623046875em;
  color: rgb(118, 118, 119);
}

.icon-file-jupyter .path9:before {
  content: "\ea16";
  margin-left: -0.8623046875em;
  color: rgb(243, 119, 38);
}

.icon-file-jupyter .path10:before {
  content: "\ea17";
  margin-left: -0.8623046875em;
  color: rgb(243, 119, 38);
}

.icon-file-jupyter .path11:before {
  content: "\ea18";
  margin-left: -0.8623046875em;
  color: rgb(158, 158, 158);
}

.icon-file-jupyter .path12:before {
  content: "\ea19";
  margin-left: -0.8623046875em;
  color: rgb(97, 98, 98);
}

.icon-file-python .path1:before {
  content: "\ea1a";
  color: rgb(56, 122, 177);
}

.icon-file-python .path2:before {
  content: "\ea1b";
  margin-left: -1.0048828125em;
  color: rgb(255, 255, 255);
}

.icon-file-python .path3:before {
  content: "\ea1c";
  margin-left: -1.0048828125em;
  color: rgb(255, 207, 63);
}

.icon-file-python .path4:before {
  content: "\ea1d";
  margin-left: -1.0048828125em;
  color: rgb(255, 255, 255);
}

.icon-file-iso .path1:before {
  content: "\ea1e";
  color: rgb(226, 229, 231);
}

.icon-file-iso .path2:before {
  content: "\ea1f";
  margin-left: -0.869140625em;
  color: rgb(176, 183, 189);
}

.icon-file-iso .path3:before {
  content: "\ea20";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-file-iso .path4:before {
  content: "\ea21";
  margin-left: -0.869140625em;
  color: rgb(65, 42, 141);
}

.icon-file-iso .path5:before {
  content: "\ea22";
  margin-left: -0.869140625em;
  color: rgb(202, 209, 216);
}

.icon-file-iso .path6:before {
  content: "\ea23";
  margin-left: -0.869140625em;
  color: rgb(255, 255, 255);
}

.icon-file-iso .path7:before {
  content: "\ea24";
  margin-left: -0.869140625em;
  color: rgb(255, 255, 255);
}

.icon-file-iso .path8:before {
  content: "\ea25";
  margin-left: -0.869140625em;
  color: rgb(255, 255, 255);
}

.icon-link-4:before {
  @extend .base-ico-style-1;
  content: "\e993";
  font-weight: 600;
}

.icon-zendesk-icon:before {
  content: "\e92a";
}

.icon-external-link2:before {
  content: "\e993";
  @extend .base-ico-style-1;
  color: #797975;
  font-weight: bold;
}


.icon-file-link .path1:before {
  content: "\e92b";
  color: rgb(112, 182, 193);
}

.icon-file-link .path2:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-file-link .path3:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-notification:before {
  content: "\ea29";
}

.icon-line-notification:before {
  content: "\ea2a";
}

.icon-debain:before {
  content: "\ea2a";
  color: #a80030;
}

// Admin
.access_control {
  @extend .side-nav-icon;
  @extend .icon-ico-access-control;
}

.side-nav-icon {
  font-family: 'web-icon' !important;
  font-weight: 500;
  font-size: 20px;
}

// Breafcase
.briefcase {
  @extend .side-nav-icon;
  @extend .icon-ico-briefcase;
}

//profile
.profile {
  @extend .side-nav-icon;
  @extend .icon-profile;
}

.myFriends {
  @extend .side-nav-icon;
  @extend .icon-my-friends;
}

.Software_License_Agreements {
  @extend .side-nav-icon;
  @extend .icon-software-license1;
}

.Reports {
  @extend .side-nav-icon;
  @extend .icon-software-license1;
}

.Software_Evaluation_License {
  @extend .side-nav-icon;
  @extend .icon-software-evaluation;
}

.Software_Evaluation_License {
  @extend .side-nav-icon;
  @extend .icon-software-evaluation;
}

.Non_disclosure_agreements {
  @extend .side-nav-icon;
  @extend .icon-software-evaluation;
}

.NDAs {
  @extend .side-nav-icon;
  @extend .icon-software-evaluation;
}

.Licenses {
  @extend .side-nav-icon;
  @extend .icon-licenses;
}

.wall {
  @extend .side-nav-icon;
  @extend .icon-ico-wall;
}

.Documents {
  @extend .side-nav-icon;
  @extend .icon-ico-documents;
}

//admin
.access_control {
  @extend .side-nav-icon;
}

.user_setup {
  @extend .side-nav-icon;
  @extend .icon-ico-user-setup;
}

.item_setup {
  @extend .side-nav-icon;
  @extend .icon-ico-item-setup;
}

.system_settings {
  @extend .side-nav-icon;
  @extend .icon-ico-settings;
}

// projects
.Projects {
  @extend .side-nav-icon;
  @extend .icon-ico-projects;
}

.Project {
  @extend .side-nav-icon;
  @extend .icon-ico-project;
}

.Products {
  @extend .side-nav-icon;
  @extend .icon-ico-product;
}

.Downloads {
  @extend .side-nav-icon;
  @extend .icon-ico-product;
}

.Videos {
  @extend .side-nav-icon;
  @extend .icon-play;
}

.Search {
  @extend .side-nav-icon;
  @extend .icon-search-new;
}

.Notifications {
  @extend .side-nav-icon;
  @extend .icon-line-notification;
}

// Breafcase
.zendesk {
  @extend .side-nav-icon;
  @extend .icon-zendesk-icon;
}