/*
	© 2019 Websilo.
	User may not copy, modify, distribute, or re-bundle or otherwise make available this code;
*/

// Sass Colour Function Calculator
//
// For color contrast, darken, lighten http://razorjam.github.io/sasscolourfunctioncalculator/

// Colours Naming and values
//
// All colour variable names should come from http://chir.ag/projects/name-that-color/

// brand
$royalp-blue 	: #396FE1;
$catskill-white : #f1f5f9;
$pale-ky 		: #687084;
$madison 		: #103170;
$black 			: #000000;
$red-orange 	: #ED4C67;
$ship-cove		: #8091C5;
$dusty-gray		: #959595;
$zircon 		: #edf2ff;
$athens-gray 	: #e9edf0;


$cornflower-blue                    : #0788d0;
$thunder                            : #231f20;
$cloud-burst                        : #212e43;
$blaze-orange                       : #ff6600;


// notification
$jade                               : #04b775;
$sunset-orange                      : #ff4d4d;
$wattle                             : #d5d32f;
$picton-blue                        : #4dbce9;

// icons
$supernova                          : #ffcd00;
$porcelain                          : #eaecee;
$pale-sky                           : #6b777d;

//button
$fruit-salad                        : #4caf50;
$sunset-orange                      : #ff4d4d;
$river-bed                          : #47525a;

// Text
$thunderbird                        : #c81313;
$steel-blue                         : #5298bf;
$shuttle-gray                       : #556674; //u
$woodsmoke                          : #17191b;
$hit-gray                           : #a0aab5;
$white                              : #ffffff;
$black                              : #000000;
$shark								: #212426;  //u
$oslo-gray							: #8d9091;



// border


// background
$bg-dark-black : #11171d;
$bg-dark-blue :#032e51;


// font color


//
// Brand color
//

$websilo-brand-primary                   : $cornflower-blue;
$websilo-brand-secondary                 : $thunder;
$websilo-brand-tertiary                  : $cloud-burst;
$websilo-brand-forth                     : $blaze-orange;

// Colors
//
// Grayscale and brand colors for use across Bootstrap.




$websilo-color-primary                   : $websilo-brand-primary;
$websilo-color-secondary                 : $websilo-brand-secondary;
$websilo-color-tertiary                  : $websilo-brand-tertiary;

// $websilo-color-success                  : #4a7f35;
// $websilo-color-success-background       : #eef7e4;
// $websilo-color-warning                  : #8e7728;
// $websilo-color-warning-background       : #f9f5cd;
$websilo-color-error                    	  : #C33C48;
// $websilo-color-error-background         : #FFE9F1;
// $websilo-color-info                     : $websilo-color-theme-light;
// $websilo-color-info-background          : $websilo-color-theme-light;

//link
$websilo-color-link                      : $steel-blue;

// $websilo-color-link-hover                : darken($websilo-color-link, 15%);

// $websilo-color-primary-hover             : saturate(lighten($websilo-color-link, 4), 10);
// $websilo-color-primary-active            : desaturate(darken($websilo-color-link, 5), 10);
// $websilo-color-primary-disable           : lighten($websilo-color-link, 18);

// $websilo-color-secondary-hover          : saturate(lighten($websilo-color-secondary, 4), 4);
// $websilo-color-secondary-active         : darken($websilo-color-secondary, 6);

// $websilo-color-tertiary-hover           : $websilo-color-secondary;
// $websilo-color-tertiary-active          : darken($websilo-color-secondary, 6);

// $websilo-color-dark-copy                : #1f2223;
// $websilo-color-copy                     : #404040;
// $websilo-color-light-copy               : lighten($websilo-color-copy, 22);

//TODO: FIND OUT
// $websilo-color-range-off                : #dcdcdc;
// $websilo-color-range-on                 : $websilo-color-primary;

//Stars
// $websilo-color-star-filled              : #5b7f8c;
// $websilo-color-star-empty               : #d0e3ee;
// $websilo-color-star-error               : #C33C48;
// $websilo-color-star-empty-disable       : #c7dde9;


// background


// $websilo-color-app-background            : $alabaster;
// $websilo-color-app-background-column-list: $aqua-haze;




// icons
// $websilo-color-icon                     : #ffffff;

//Status
//$websilo-color-status                    : #fe6b38;

// $websilo-pusher-bg                      : #ffffff;

//Datepicker
// $websilo-days-disabled                  : #c5dbe7;

//Home Slide Theme
// $websilo-color-slide-background         : #e9f1f5;

//Placeholder Colors
// $websilo-color-placeholder              : #aab0b3;

// heading line border color
// $websilo-color-hr-border                 : $athens-gray;

//   Status colors
$websilo-color-status-available          : #9BCA6A;
$websilo-color-status-not-connected		: #FC4242;
$severity-cover-by-color 		    : #D8A521;

// Icon

// $websilo-color-navigation-icon           : #adb2b8;

// Layout border colors
// $websilo-color-layout-border             : $ghost;

// Application severity type module color
$severity-warning-color : $wattle; //u
$severity-emergency-color : $thunderbird;  //u
$severity-critical-color : $thunderbird; //u
$severity-normal-color : $thunderbird; //u
$severity-important-color : $picton-blue; //u


// Forms fields, labels

// $clr-form-border:  $primary-2;/
$size-form-border : 2;

