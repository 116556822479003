.videos-listing{
  padding: 1em; 
  min-height: 400px;
    max-height: 645px;

    .ui{
      &.styled.accordion {
          width: 100%;
      }
      &.styled.accordion, 
      &.styled.accordion .accordion{
        box-shadow: none;
        border: solid 1px $black;
        border-radius: $border-radius-form-field;   

        .title:not(.ui){
          font-family:$font-family-base ;
          color: $black;
          font-size: em(17);
          line-height: 26px;
        
          &.active{
            color: $secondary;
            border-bottom: solid 1px $primary-2;
            border-top: solid 1px $primary-2;
            background: $porcelain;
          }
          
        
          &:hover{
            color: $secondary;
            background: $porcelain;
          }           

          .plus.icon{ color: $primary-2;
            &::before{
              content: "+";
              font-size: 24px;
              font-weight: 500;  
            }

             &:hover{
              color: $secondary;
            }
          }
        }
        .active.content {  
          margin-left: 0;
          border-bottom: solid 1px black;
          padding: .5em 0 .5em !important;
          position: relative;

          li{
            padding:em(8) em(22);    
            a{
              color: $black;font-size: em(16);
                &:hover{
                  text-decoration: underline;
                }
            }
          }
        }   
      }
    }
  }


.video-js {
  box-shadow:  0 0 9px #adadad;

  button.vjs-big-play-button{
    border-color: $secondary ;
    &:hover{
      border-color: $secondary ;

      .vjs-icon-placeholder::before{
        color: $secondary;
      }
    }
  }
}

.play.circle::before{
  color: $primary-2;
}