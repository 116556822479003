.pdf-preview-setting {
    border: solid 1px $primary-2;
    text-align: center;
    font-size: 34px;
    min-height: 56px;
    line-height: 50px;
}

.email-template-preview {
    width: 100%;
    height: 500px;
    border: 0;
}

.form-label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: $primary-2;
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}