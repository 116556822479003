.ui {
  font-family: $font-family-base;

  &.base-font {
    font-family: $font-family-base;
  }

  &.segment {
    background-color: $white;
    border-radius: $base-border-radius;
    border-radius: 0;
    border: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 18px 14px;

    .header {
      padding: 0 4px;

      button {
        float: right;
        padding: 6px 12px 6px 12px;
        margin-left: 6px;
      }
    }

    .table-wrapper {
      margin: 6px 0 0 0;
    }
  }

  a {
    font-weight: 600;
    font-size: 13px;

    &:hover {
      text-decoration: underline;
    }
  }

  &.modal {
    .close {
      top: 0.8em;
      right: 0.8em;
      color: #3e3b3b;
    }
  }

  &.header {
    @extend .base-font;
  }

  &.table {
    color: $black;
    font-size: 12px;

    tfoot th {
      padding-left: 0;
      padding-right: 0;
    }

    th {
      &.center {
        text-align: center;
      }
    }
  }

  &.list {
    font-family: $font-family-base;

    .item {
      .content {
        font-family: $font-family-base;

        .header {
          font-family: $font-family-base;
        }
      }
    }
  }

  &.tabular {
    &.menu {
      border-color: $primary-2;

      .active.item {
        border-color: $primary-2;
        color: $secondary;
      }
    }
  }

  &.attached {
    &.segment {
      border-color: $primary-2;
    }
  }

  &.pagination.menu {
    @extend .base-font;
    height: 30px;
    min-height: 30px !important;
    box-shadow: none;

    .item {
      display: inline-block;
      max-width: 60px;
      min-width: 30px;
      padding: 6px 8px 6px 8px;
    }

    .active.item {
      background-color: $app-base-color;
      border-radius: 0;
      border: none;
      color: $white;
      height: 28px;
      line-height: 2px;
    }
  }

  &.selection.dropdown {
    border-radius: $border-radius-form-field;
    box-shadow: none !important;
    height: 39px !important;
    width: 100%;

    &:hover {
      border-color: $primary-2;
    }
  }

  &.label {
    border-radius: $border-radius-form-field;
    box-shadow: none;
  }

  &.message {
    border-radius: $border-radius-form-field;
  }

  .search-bar {
    border-radius: $border-radius-form-field;
    box-shadow: none;
  }

  &.input {
    font-size: 12px;
    box-shadow: none;
  }

  .button-basic,
  .button-danger,
  .button-primary {
    border-radius: $border-radius-form-field;
    margin-right: 8px;
    text-transform: uppercase;

    &:focus {
      border-radius: 0;
    }
  }

  .button-basic,
  .button-danger,
  .button-primary {
    background-color: $white;
    cursor: pointer;
    font-family: $font-family-base;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 19px;
    padding: 0.55em 15px;
    text-decoration: none;
    transition: all 0.3s linear;

    &:focus,
    &:active {
      border-radius: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .button-basic {
    border: solid 2px #dce3ea;
    color: #464646;
  }

  .button-primary {
    background: transparent;
    color: $black !important;
    border: solid 2px $black !important;

    &:focus,
    &:active {
      border-radius: 0;
      background: transparent !important;
    }
  }

  &.button-primary:hover,
  &.button-primary:active,
  &.button-primary:focus {
    color: white !important;
    border: solid 2px $app-base-color !important;
    background-color: $app-base-color !important;
  }

  .button-basic:hover {
    color: black;
    background-color: #dce3ea;
  }

  &.red.button,
  &.red.buttons .button {
    background: transparent;
    border-color: $clr-button-border-red;
    color: $clr-button-font-red;
    transition: all 0.3s linear;

    &:hover {
      background: $bg-button-red;
      color: $primary;

      .icon {
        color: $primary;
      }
    }

    .icon {
      color: $clr-button-font-red;
      opacity: 1;
    }
  }

  &.button.toggle {
    &.active {
      background: $secondary !important;
      border-color: $secondary;

      &:hover {
        border: solid 2px #dce3ea !important;
        color: #464646 !important;
        background: transparent !important;
      }
    }
  }


  .ui.toggle.checkbox {

    input:checked~.box:before,
    input:checked~label:before {
      border-radius: 500rem !important;
      background: $secondary !important;
    }

    label:before {
      border: solid 1px #797979;
    }

    label::after {
      border: solid 1px #806b6b;
    }
  }


  &.fixed {
    &.table {
      td {
        white-space: nowrap;
      }

      th {
        text-overflow: inherit;
      }
    }
  }

  a {
    text-decoration: none;
  }

  &.button {
    &.large {
      font-size: 1.2rem;
      letter-spacing: 0.6px;
      font-weight: 600;
    }

    &.primary {
      &.large {
        font-size: 1.2rem;
        letter-spacing: 0.6px;
        font-weight: 600;
      }
    }
  }

  // error msg

  &.basic.red.label {
    position: absolute;
    top: -29px;
    right: 12px;
    z-index: 1;
  }

  &.modals {
    .breadcrumbs {
      word-break: break-all;
      width: 95%;
    }

    .ui.large.icon.button {
      position: absolute !important;
      right: 0;
      padding: 5px;
      line-height: 18px;
    }

    .actions {
      .ui.button {
        @extend .button-basic;
      }

      .ui.primary.button {
        @extend .button-primary;
      }
    }
  }

  &.form {
    .input-ui {
      @extend .base-font;
      border-radius: 0;
      border: $border-size-t2 solid $clr-input-border;
      font-size: 16px;
      padding-top: 0.481em;
      padding-bottom: 0.481em;

      &:focus {
        border-radius: 0;
      }
    }

    input {

      &:not([type]),
      &[type="date"],
      &[type="datetime-local"],
      &[type="email"],
      &[type="file"],
      &[type="number"],
      &[type="password"],
      &[type="search"],
      &[type="tel"],
      &[type="text"],
      &[type="time"],
      &[type="url"],
      &[type="textarea"] {
        @extend .input-ui;
      }
    }

    textarea {
      @extend .input-ui;
    }

    select {
      @extend .input-ui;
      padding-top: 0;
      padding-bottom: 0;
      height: 38.5px;

      &.multi-select {
        height: 80px !important;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  &.selection.dropdown {
    @extend .base-font;
    border-radius: $border-radius-form-field;
    border: $border-size-t2 solid $clr-input-border;
    width: 100%;
  }

  // Grid Tool tip 
  &.grid-tooltip {
    box-shadow: none;
    padding: em(4) em(6);
    border-radius: 0;
    border-color: $primary-2;
    font-size: em(13);
    word-break: break-word;
    max-width: 600px;
    min-width: 120px;

    &::before {
      // display: none;
      //  1px 1px 0 0 $primary-2;
      //  box-shadow: 1px 1px 1px 1px $primary-2 !important;
    }

    &.bottom::before {
      box-shadow: -1px -1px 0 0 $primary-2 !important;
    }

    &.top::before {
      // margin-top: em(6);
      box-shadow: 1px 1px 0 0 $primary-2 !important;
    }
  }

}

//End

.segment {
  &.dashboard-table {
    margin: 6px 0 0 0;
  }
}

.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table>tr:nth-child(2n) {
  background-color: rgba($color: #e9edf0, $alpha: 0.35);
}

.ui.menu {
  @extend .base-font;
}

.ui.table {
  border: none;

  thead {
    tr {
      th {
        background: none;
        border: none;
        text-transform: uppercase;
      }
    }
  }

  tr {
    td {
      border: none;
    }
  }

  tfoot th {
    background: white;
  }
}

.ui.stackable.menu {
  box-shadow: 0 5px 10px 1px rgba(63, 63, 68, 0.05);
  align-items: center;
  border-bottom: 0;
  height: 83.4px !important;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .link {
    padding: 8px 15px 9px;

    &:hover {
      text-decoration: none;
    }
  }

  >.item {

    &::after,
    &::before {
      display: none !important;
    }
  }

  .item {
    @extend .base-font;
    color: $secondary;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background: transparent;
    margin-right: 20px;
    padding-right: 0;
    padding-left: 0;

    &::before {
      display: block;
      background: $secondary;
      content: "";
      width: 0px;
      height: 3px;
      position: absolute;
      top: 5px;
      left: 0;
      transition: width 0.3s linear;
    }

    &::after {
      background: $secondary;
      display: block;
      content: "";
      width: 0;
      height: 3px;
      position: absolute;
      bottom: -4px;
      right: 0;
      transition: width 0.3s linear;
    }

    &:hover {
      color: $secondary;

      &::before {
        display: block;
        background: $secondary !important;
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        top: 0px;
        left: 0;
      }

      &::after {
        display: block;
        background: $secondary !important;
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: -4px;
        right: 0;
      }
    }

    @media only screen and (min-width: $computerBreakpoint) and (max-width: $largestSmallMonitor) {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 12px;
    }

    img:not(.ui) {
      margin: 5px 10px 0 13px;
      width: 106px;
    }

    &:hover {
      background: transparent;
    }
  }

  .active.item {
    @extend .base-font;
    color: $secondary;

    &::after {
      width: 100%;
    }
  }

  .ui.dropdown .menu>.item {
    @extend .base-font;
  }
}

.ui.vertical.right.tabular.menu {
  border: none;
}

.ui.checkbox label:before {
  border-radius: 0;
}


.ui.checkbox input:checked~.box:before,
.ui.checkbox input:checked~label:before {
  border-radius: 0 !important;
  background-color: $app-base-color !important;
  border: solid 1px $app-base-color;
  color: $white !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
  color: $white !important;
}



.ui.items>.item {
  object-fit: contain;
  background-color: rgba($color: #e9edf0, $alpha: 0.35);
  padding: 10px 5px;
  margin: 10px 0px;
}

.ui.items>.item>.image>img {
  object-fit: cover;
  border-radius: 50%;
}

.ui.items>.item>.content>.header {
  @extend .base-font;
  font-size: em(16);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
}

.ui.items>.item .extra {
  @extend .base-font;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
}

.ui.items>.item>.content>.description {
  margin-top: 0px;
}

.ui.basic.segment {
  margin: 0px;
  padding: 0px 12px;
}

.ui.label {
  @extend .base-font;
  // border-radius: 3px;
  border: solid 1px #dce3ea;
  background-color: #dce3ea;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
  color: #697081;
}

.ui.message {
  // border-radius: 5px;
  background-color: rgba($color: #e9edf0, $alpha: 0.35);
}

.ui.primary.button {
  @extend .base-font;
  background-color: $bg-button-t3;
  border-radius: $button-border-radius-t3;
  border: solid $button-border-size-t3 $clr-button-border-t3;
  color: $clr-button-font-t3;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: normal;
  margin: 8px;
  padding: 5px 15px;
  text-transform: uppercase !important;

  &:hover {
    background-color: $hover-bg-button-t3;
    border-color: $hover-clr-button-border-t3;
    color: $hover-clr-button-font-t3;
    border-radius: 0;
  }

  &:focus {
    border-radius: 0;
  }
}

.ui.card>.content>.header {
  font-family: $font-family-base;
  font-size: em(16) !important;
  font-weight: 600;
  line-height: normal;
  color: $white;
}

.ui.card {
  width: 100%;
}

.ui.basic.teal.label {
  margin: 5px;
}

.ui.disabled.input {
  opacity: 1;
  background: #f8f8f8;
}

.ReactPasswordStrength {
  border: none !important;
  box-sizing: border-box !important;
  color: transparent !important;
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
}

.ReactPasswordStrength-strength-bar {
  top: -2px !important;
}

.ReactPasswordStrength-strength-desc {
  padding: 8px 12px !important;
}

.ui.sidebar {
  position: absolute;
}

// Error msg setting
.error.required {
  position: relative;
}

.filter-box-wrapper {
  background: #f1f5f9;
  border-radius: 4px;
  padding: 0 12px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}

.react-datepicker-wrapper {
  width: 100%;

  input[type="text"] {
    width: 100% !important;
  }
}


.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
  color: $white !important;
  border: solid 2px $app-base-color !important;
  background-color: $app-base-color !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  border-color: $primary-2;
}

.ui.checkbox label,
.ui.checkbox+label {
  color: $primary-2;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
  background: $app-base-color;
}

.ui.radio.checkbox input:checked~.box:before,
.ui.radio.checkbox input:checked~label:before {
  background: $white !important;
  border-radius: 50% !important;
  border-color: $app-base-color !important;

}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
  color: $app-base-color !important;
}

.ui.radio.checkbox input:focus:checked~.box:after,
.ui.radio.checkbox input:focus:checked~label:after {
  color: $app-base-color !important;
  background: $app-base-color;
  border-color: $app-base-color !important;
}

.ui.checkbox input:focus~.box:before,
.ui.checkbox input:focus~label:before {
  border-color: #f59080 !important;
}

.col-country-code {
  .selection.dropdown {
    min-width: auto;
  }
}

.ui.message.nda-non-disclosure-msg {

  // Message list override
  .list:not(.ui) {}

  .list:not(.ui) li {
    list-style-type: disc !important;
    margin: 8px 0 0 0 !important;
  }

  .list:not(.ui) li:before {
    display: none !important;
  }
}

.custom-form-note {
  font-style: italic;
  font-weight: normal !important;
  margin: -5px 0 0 0 !important;
  font-size: em(15) !important;
}