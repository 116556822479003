.ui {
  &.login-wrapper {
    margin-top: 0;
    min-height: calc(100vh - 83px);

    .base-heading {
      font-size: 26px;
      letter-spacing: 0.75px;
      line-height: 44px;
    }

    &::before {
      background: url("/bg-black-HeaderGraphic-2.png") no-repeat 0 100%;
      background-attachment: fixed;
      background-size: 28%;
      bottom: 0;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      width: 50%;
      z-index: -1;
    }

    &::after {
      background: url("/bg-black-HeaderGraphic-1.png") 109% 0 no-repeat;
      background-attachment: fixed;
      background-size: 40%;
      content: "";
      height: 80%;
      position: absolute;
      right: 0;
      top: 0;
      width: 42%;
      z-index: -1;
    }

    .form {
      .field {
        margin-bottom: 2px;
      }

      input {
        &:not([type]),
        &[type="password"],
        &[type="text"] {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.5px;
        }
      }
    }

    .ui.stacked.segment {
      padding: 44px 32px 44px 32px;
      box-shadow: 0 0 20px #d6d6d6;
      margin: 40px 0 14px 0;
      width: 100%;
      border-radius: 2px;

      &::after {
        display: none;
      }
    }

    .ui.forgot-signup-block {
      margin-top: 4px;
      font-size: 15px;
    }
    .sso-wrapper>div{
      width:50%,
    }
    .sso-wrapper {
      display: flex;
      min-height: 100px;
      .google-wrapper {
        // margin-right: 1%;

        // .g_id_signin {
        //   width: 220px;
        //   margin: 0 auto 20px;
        // }
      }

      .microsoft-wrapper {
        
        #login-microsoft {
          height: 40px;
          line-height: 20px;
          font-size: 15px;
          font-family: 'Segoe UI Regular', Tahoma, Geneva, Verdana, sans-serif;
          color: #5E5E5E;
          border: 1px solid #dfdfdf;
          border-radius: 3px;
          padding: 9px 12px;
          background-color: #fff;
          vertical-align: top;
          cursor: pointer;
          width: 100%;

          img {
            margin-right: 12px;
            float: left;
          }
        }
      }

      @media only screen and (max-width: 603px) {
        flex-direction: column;
        .microsoft-wrapper {
          width: 100%;
          margin-top: 5px;
        }

        .google-wrapper {
        
        }
      }
    }
  }

  &.button.btn-google-login {
    display: none;
  }

  .block-or {
    font-size: 18px;
    color: #c2c2c2;
    margin: 26px 0;
    display: block;
    position: relative;
    font-weight: 600;

    &::before {
      background: #e5e4e4;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 42%;
    }

    &::after {
      content: "";
      background: #e5e4e4;
      height: 2px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 42%;
    }
  }
}

.label-signup-policy {
  .checkbox {
    float: left;
  }
  span {
    float: left;
    line-height: 1;
    margin: 0 0 0 6px;
  }

  .error-message {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }
}

.two-factor-popup {
  max-width: 387px;

  .lable-2-factor {
    display: block;
    font-size: 1em;
    font-weight: 700;
    margin: 14px 0 4px 0;
    text-align: left;
    width: 100%;
  }

  .factor-block {
    display: flex;
    justify-content: space-between;
    width: auto !important;
  }

  .styles_react-code-input__CRulA {
    display: flex;
    justify-content: space-between;
    width: 100%;
    > input {
      color: $flamingo !important;
      font-family: $font-family-base !important;
      font-weight: bold;
      border-radius: 0;
      border: none;
      margin: 0 0 0 18px;
      border-bottom: 2px $primary-2 solid;
      width: 38px !important;
      outline: none;

      &:last-child,
      &:first-child {
        border-radius: 0;
        border-right: transparent;
      }
    }
  }

  .styles_react-code-input__CRulA > input:focus {
    border: 0;
    border-bottom: 2px $secondary solid;
    caret-color: $secondary;
    outline: none;
  }
}

.welcome-popup-wrapper {
  .welcome-info {
    margin: 0;
    padding: 6px 0 0 19px;

    li {
      color: $secondary;
      margin: 0 0 12px 0;
      padding: 0 0 0 0;

      p {
        color: $primary-2;
        display: initial;
        line-height: 20px;
      }
    }
  }

  .label-bottom {
    margin: 20px 0 16px 0;
    font-weight: 600;
  }
  .label-top {
    margin: 0px 0 8px 0;
    font-weight: 600;
    span {
      color: $secondary
    }
  }

  .actions {
    .ui.checkbox {
      margin: 8px 0 0 0;
    }

    .ui.primary.button {
      margin: 0;
    }
  }
}
