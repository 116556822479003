.base-segment-wrapper,
.dashboard-card {
  table {
    min-height: 120px;
    //set table scroll for mobile and tablet
    @media only screen and (max-width: $tabletBreakpoint) {
      min-width: 1000px;
    }
    tbody {
      .ui.segment {
        box-shadow: none;
        min-height: 140px;
      }
    }
  }

  .header {
    color: $clr-heading;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
  }
}

.dashboard-card {
  .sp-case-row {
    padding: 0 !important;
  }

  .control-action-wrapper {
    .button-primary {
      margin: 0;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &:last-child {
    margin-bottom: 16px !important;
  }

  .table-wrapper {
    max-height: 280px;
    overflow-y: auto;

    .loader {
      min-height: 150px;
    }
  }

  .table-wrapper-with-pagination {
    max-height: 280px;
    overflow-y: auto;

    a:hover {
      text-decoration: none;
    }
  }

  .ui.accordion,
  .ui.accordion .accordion {
    .title {
      .caret.icon {
        float: right;
        font-size: 1.3em;
        font-weight: normal;
        line-height: 1.5em;
        color: $clr-heading !important;
      }
    }
  }

  .mobile-card-view-wrapper {
    border-radius: 6px !important;
    width: 100%;

    .content {
      background: #f7f9fa;
      border-radius: 6px !important;
      box-shadow: -1px 3px 4px 0 #d4d4d5, 0 2px 3px 1px #cecece;
      padding: 20px 8px 18px 10px;

      .ui.grid > .column:not(.row) {
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
      }
    }

    .data-view-group-box {
      margin: 0 0 4px 0;

      label {
        color: #676666;
        font-size: 0.9em;
        letter-spacing: 0.05em;
        font-weight: 500;
      }

      p {
        color: rgba(0, 0, 0, 0.87);
        vertical-align: inherit;
        word-wrap: break-word;
        font-weight: 700;
        font-size: 0.96em;
      }
    }
  }
}

.base-segment-wrapper {
  .table-wrapper,
  .table-wrapper-with-pagination {
    width: 100%;
    // overflow-y: auto;

    a:hover {
      text-decoration: none;
    }
  }

  .ui.segment {
    box-shadow: none !important;
  }
}
