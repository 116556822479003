.no-padding-horizontal-im{
	padding-left: 0 !important;
	padding-right: 0 !important;	
}

.no-margin-horizontal-im{
	margin-left: 0 !important;
	margin-right: 0 !important;	
}

.base-heading{
	color: $black !important;
}