.ticket-external-link {
    font-size: 18px;
    color: #F55036 !important;
    letter-spacing: 0.6px;
    padding: 0 0 4px 0;
    display: flex;
    align-items: center;
}
.ticket-external-link:hover {
    text-decoration: underline;
    cursor: pointer;
}