.simple-link-button {
  border-radius: 15.5px;
  border: solid 1px #dce3ea;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #687084;
  padding: 5px;
  margin: 5px;
}

.assets {
  background-color: rgba($color: #e9edf0, $alpha: 0.35);
}

.items-heading {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.items-upload-sub-heading {
  font-weight: bold;
  color: $app-base-color;

  margin-left: -0.5rem !important;
}

.image-icon {
  object-fit: contain;
  padding: 5px;
  margin: 5px;
  width: 60px;
  border-radius: 20px;
}

.delete-icon {
  padding: 5px;
  margin: 15px 15px 5px 0px;
}

.edit-icon {
  padding: 5px;
  margin: 15px 0px;
  object-fit: contain;
}

#document-dropdown-tags {
  height: auto !important;
}

//
.member-card-wrapper {
  .header {
    width: 100%;

    .button-basic.ui.mini.button {
      float: right;
      margin: 0 0 0 6px;
    }

    a {
      cursor: default !important;

      &:hover {
        text-decoration: none;
      }
    }

    .delete {
      line-height: 30px;
      width: 38px;
      height: 30px;
      background: WHITE;
      border-radius: 12px;
      border: solid 1px red;
      float: right;
      font-size: 14px;
    }

    .member-name-status {
      color: $madison;
      font-size: 16px;
      margin-right: 6px;
    }
  }
}

.project-invites-block {
  .ui.divided.items>.item:first-child {
    padding-top: em(16) !important;
  }

  .ui.divided.items>.item:last-child {
    padding-bottom: em(16) !important;
  }
}


.project-item {
  .project-avatar-box {
    width: em(70);
    display: flex;
    background: #f5e9e6;
    height: em(70);
    padding: em(6);
    align-items: center;
    margin: 0 em(10) 0 0;
    border: 1px solid #d1cdcc;
    border-radius: em(10);

    img {
      width: 100%;
    }
  }
}