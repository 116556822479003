.briefcase-send {
  .file-list {
    height: 60px;
  }
  
  .share-model {
    border: 3px solid $blaze-orange;
    border-radius: 10px;

    button.toggle-share-page {
      width: 100%;
      margin-bottom: 15px;

      &.active,
      &:hover {
        border: 3px solid $blaze-orange !important;
        background-color: $blaze-orange !important;
        color: $white !important;
      }
    }

    .share-model-grid {
      width: 100%;
    }
  }
}
