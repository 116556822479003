/*
  © 2019 Websilo.
  User may not copy, modify, distribute, or re-bundle or otherwise make available this code;
*/

// AMR Variables
//
// override bootstrap variables by hiplink application variables.

@import "_colors.scss";
// @import "_options.scss";
// @import "_spacing.scss";
// @import "_sizing.scss";
@import "_typography.scss";

//
// Color system
// http://chir.ag/projects/name-that-color/#4C4F56

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$royalp-blue: #396FE1;
$catskill-white : #f1f5f9;
$pale-sky: #687084;
$madison: #103170;
$black: #000000;
$red-orange: #ff3824;
$ship-cove: #8091C5;
$dusty-gray: #959595;
$zircon: #edf2ff;
$athens-gray: #e9edf0;

$alizarin-crimson :#db2828;


// Groq 
$flamingo : #F55036;
$porcelain :#EAEDEF;




//
// Sizes system

$border-size-t2 : 1.5px;





$primary: $white !default;
$primary-2: $black !default;
$secondary: $flamingo !default;



// Body
//
// Settings for the `<body>` element.

$body-bg: $primary !default;
$body-color: $black !default;

// Links
//
// Style anchor elements.

$link-color: $secondary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;




// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'cerebrisanspro-regular',
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
// $font-family-monospace:       source_sans_probold, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

// $font-family-base-secondary:            $font-family-base, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;;


$font-family-regular: cerebrisanspro-regular;
$font-family-light: cerebrisanspro-light;
$font-family-bold: cerebrisanspro-bold;
$font-family-medium: cerebrisanspro-medium;

// $font-family-third : anko-personal; 

$font-size-base: 16px;

$base-border-radius : 10px;
$border-radius-type-1: 3px;

$app-base-color: $secondary;


$clr-heading: $black;
$border-radius-form-field: 0;

$clr-sub-nav : #000;
$clr-sub-nav-active-dot: $app-base-color;
$clr-sub-nav-dot: #d0d0d0;


// Layout setting

/*-------------------
     Breakpoints
--------------------*/
$mobileBreakpoint : 320px;
$tabletBreakpoint : 768px;
$computerBreakpoint : 992px;
$largeMonitorBreakpoint : 1200px;
$widescreenMonitorBreakpoint : 1920px;

/* Responsive */
$largestMobileScreen : ($tabletBreakpoint - 1px);
$largestTabletScreen : ($computerBreakpoint - 1px);
$largestSmallMonitor : ($largeMonitorBreakpoint - 1px);
$largestLargeMonitor : ($widescreenMonitorBreakpoint - 1px);

// --------- buttons  --------- START -- //

// button type 2
$bg-button-t2 : transparent;
$button-border-radius-t2: 0;
$button-border-size-t2: 2px;
$clr-button-border-t2 : $primary-2;
$clr-button-font-t2: $primary-2;
//hover
$hover-clr-button-font-t2: $primary;
$hover-bg-button-t2 : $app-base-color;
$hover-clr-button-border-t2 : $app-base-color;

// button type 3
$bg-button-t3: $app-base-color;
$button-border-radius-t3: 0;
$button-border-size-t3: 2px;
$clr-button-border-t3: $app-base-color;
$clr-button-font-t3: $primary;
//hover
$hover-clr-button-font-t3: $primary-2;
$hover-bg-button-t3: transparent;
$hover-clr-button-border-t3 : $primary-2;


$bg-button-red : $alizarin-crimson;
$clr-button-border-red : $alizarin-crimson;
$clr-button-font-red: $alizarin-crimson;

// --------- buttons  --------- End -- //

// --------- forms  --------- START -- //

$clr-input-border : $primary-2;
$clr-form-border: $primary-2;

// --------- forms  --------- End -- //



// --------- Folder Tree --------- START -- //
$color-icon-disable : #585858;
$background-icon :#cfcfcf;
$icon-filled-height : -6.5px;
$color-disable-tree-icon : #9b9494;

$color-tree-connected-line :#989898;

// --------- Folder Tree --------- End -- //