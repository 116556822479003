/*!
 * Groq 
 * Copyright 2021
 */

// Bootstrap
//
// Includes Bootstrap from application Base, custom bootstrap first frame work
// @import "../external/bootstrap-4.1.3/scss/bootstrap.scss";

// Animate CSS
//
// Includes Animate css form Animate css
// @import "../external/animate";

// Font Awesome
//
// Includes for font icons
// @import "../external/font-awesome-all.min";

// Groq Variable
//
// Over ride Standerd Bootstrap Variable with Application Variables.
@import "variables/_variable.scss";

// Helpers
@import "helpers/_functions.scss";
@import "helpers/_utilities.scss";
@import "helpers/_helpers.scss";
@import "helpers/_mixins.scss";

/* ===============   From App.scss from root style 	=================*/
@import "components/main-loader.scss";
@import "components/notifications";
@import "components/briefcase";
@import "components/semantic-overrides.scss";
@import "components/commons.scss";
@import "components/page.scss";
@import "components/grid-view";
@import "components/login";
@import "components/products";
@import "components/dashboard";
@import "components/profile";
@import "components/context-menu";
@import "components/project.scss";
@import "components/_videos.scss";
@import "components/_header.scss";
@import "helpers/_websiloIcons.scss";
@import "components/_global-search.scss";
@import "components/notifications";
@import "components/admin-settings";

body {
  font-size: $font-size-base;
  background-color: #eaedef; // 2
  color: $body-color;
  font-family: $font-family-base;
  margin: 0; // 1
  text-align: left; // 3
}

.App {
  text-align: center;
}

.empty-height-tiny {
  height: 10px;
}

.empty-height-small {
  height: 20px;
}

.empty-height {
  height: 30px;
}

.border-bottom {
  border-bottom: 1px solid #aaa;
}

.row {
  width: 100%;
}

.disabled-link {
  pointer-events: none;
}

// Bootstrap and Semantic-ui CSS is clashing with each other and affecting Modal's UI.
// For a hack I have applied a CSS fix here.
.mini,
.tiny,
.small,
.large {
  position: relative !important;
  height: auto !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.ui.dimmer.main-loader {
  position: fixed;
}

.inline {
  display: inline-block;
}

.ck-editor__editable_inline p {
  margin: 0;
  line-height: normal;
}

.drag-drop-zone {
  padding: 2rem;
  text-align: center;
  background: $primary;
  border: solid 1px $primary-2;
  box-shadow: 2px 2px 5px #c0c0c0;
  cursor: pointer;

  p {
    color: $primary-2;
  }
}

.indicating.progress {
  margin: -20px 0 16px 0;

  .bar {
    border-radius: 0;
    height: 14px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Layout
@import "layout/_media-query.scss";
// @import "layout/_mobile.scss";
// @import "layout/_desktop.scss";
// @import "layout/_tablet.scss";