.ui.menu.main-right-menu {
  display: flex;
  flex-direction: row-reverse;
}

.main-header-search-wrapper {
  width: 280px;
  margin: 0 12px 0 0;

  .ui.icon input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 3px $secondary;
    padding-left: 0;
  }

  .search.icon {
    color: #f55036;
    opacity: 1;
    font-size: 15px;
  }
}

.login-user-details {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  min-width: 184px;
  align-items: flex-end;
  align-items: center;

  img,
  .icon-g1-state1 {
    float: left;
    font-size: em(38);
  }

  .icon-g1-state1 .path1:before {
    content: "\e9a0";
    color: $secondary;
  }

  .lud-inner {
    float: left;

    .ui {
      &.item {
        &.dropdown {
          padding: 0 0 0 12px;
          font-weight: 500;
          color: #697081;
          margin: -4px 0 0 0;

          .item {
            padding: 0 !important;

            a {
              padding: 0.78571429em 1.14285714em !important;
            }
          }

          >div {
            color: #535353;
            letter-spacing: 0.8px;
            text-transform: none;
            font-weight: 600;
          }

          a {
            width: 100%;
            display: inline-block;
          }

          &.active {
            background: none;
            color: $black;

            &.pointing {
              .menu {
                left: -137px;
                margin-top: 0.9rem !important;
                width: 244px;

                &:after {
                  left: auto;
                  right: 3%;
                }

                @media only screen and (min-width: $computerBreakpoint) and (max-width: $largestSmallMonitor) {
                  left: -144px;
                }
              }
            }
          }

          &:hover {
            background: none;
            color: $primary-2;

            a {
              color: $primary-2;
            }
          }

          .item {
            font-size: 13px !important;
            letter-spacing: 1px;
            text-transform: uppercase !important;
            font-weight: 600 !important;
            background: #f8f9fb !important;
            color: #f55036 !important;
            border-bottom: 1px #e4e4e4 solid;
          }

          &.icon {
            margin: 3px 0 0 0.5em;
          }
        }

        &::after,
        &::before {
          display: none !important;
        }
      }
    }

    .ui.stackable.menu {
      .item {
        padding: 0 18px 0 12px;
      }

      .active.item {
        background-color: none;
        border-radius: 0px;
        color: $black;
        font-weight: 600;
      }
    }
  }

  .login-user-name {
    font-size: 18px;
    font-weight: bold;
    color: $app-base-color;
    max-width: 302px;
    margin: 0 0 0 10px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.app-header-wrapper {
  z-index: 1001;
}

.responsive-menu-wrapper {
  z-index: 1001;
  background: $white;
  box-shadow: -8px -5px 10px #000;
  margin: 0;
  padding: 16px 12px;

  .app-header-wrapper {
    padding-bottom: 0 !important;
  }

  .btn-responsive {
    margin: 12px 0 0 0;
    float: left;
    font-weight: bold;
  }

  .header-search-wrapper {
    i.link.icon {
      font-size: em(20);
    }
  }

  .app-logo {
    display: inline;
    margin: 0 0 0 16px;
  }

  a {
    display: flex;
    width: 100%;

    img {
      max-width: 116px;
      width: 100%;
      margin: auto;
    }
  }

  .lud-inner {
    float: right;

    .icon-g1-state1 {
      font-size: 42px;
      cursor: pointer;

      span {
        float: left;
      }
    }

    .ui.dropdown .menu {
      left: auto;
      right: 0;
      top: 47px;
      width: 240px;
      z-index: 1000;

      .item {
        background: #f8f9fb !important;
        border-bottom: 1px #e4e4e4 solid;
        color: #f55036 !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        letter-spacing: 1px;
        padding: 0 !important;
        text-transform: uppercase !important;

        a {
          padding: 0.78571429em 1.14285714em !important;
        }
      }

      .login-user-name {
        margin-left: 0;
        color: $primary-2;
        text-transform: none;
        padding: 0.78571429rem 1.14285714rem !important;
        display: flex;
      }
    }
  }
}

.sidebar {
  overflow-x: hidden;
  width: 100%;

  &.menu {
    a {

      &:active,
      &:focus,
      &:visited,
      &:link {
        color: $app-base-color;
        text-decoration: none;
        outline: none;
      }
    }

    .link.item {
      color: $app-base-color;
      flex-direction: column;
      font-family: $font-family-base;
      font-size: 16px;
      min-width: 6em;
      text-align: left;
      text-shadow: none;

      &.active {
        font-weight: bold;
        outline: none;
      }

      &:active,
      &:focus,
      &:visited,
      &:link {
        color: $app-base-color;
        text-decoration: none;
        outline: none;
      }
    }
  }
}

.button-notification {
  position: relative;
  display: inline-block;
  margin: 0 16px 0 4px;
  cursor: pointer;

  .icon-notification {
    line-height: 43px;
    color: #535353;

    &::before {
      transform: rotate(13deg);
      display: inline-block;
      font-size: rem(28);
    }

    &:hover {
      color: #70463f;
    }

    .number-box {
      background-color: $secondary;
      position: absolute;
      width: 22px;
      height: 18px;
      color: $white;
      border-radius: 6px;
      font-size: em(8);
      right: -11px;
      text-align: center;
      padding: 2px 0 0 0;
      line-height: 12px;
      font-weight: 600;
      font-family: $font-family-base;
    }
  }
}